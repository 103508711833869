import React from 'react';

interface IProps {
	label: string;
	handleClick: (e: React.MouseEvent) => void;
}

const Button: React.FC<IProps> = ({ label, handleClick }) => {
	return (
		<>
			<button
				className={"bg-main-dark text-white font-bold text-sm px-8 py-3 rounded w-full my-4"}
				onClick={handleClick}
			>
				{label}
			</button>
		</>
	);
}

export default Button;