import React from 'react';

interface IProps {
	text: string;
	url: string;
}

const Link: React.FC<IProps> = ({ text, url }) => {
	return (
		<>
			<a
				href={url}
				target={'_blank'}
				rel={"noreferrer"}
				className={"text-main font-bold"}
			>
				{text}
			</a>
		</>
	);
}

export default Link;