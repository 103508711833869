import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import qs from "qs";
import { isNil } from "ramda";

import { DaySummary, Footer } from "../../components";
import { fetchExport } from "../../store/export/export.actions";
import { MapHelper } from "../../utils/map";
import { useExport } from "../../store/export/export.hooks";
import { pathNames } from "../../router/routes";
import Balance from "../../components/Balance";

interface IProps {}

const Export: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { workspace, token, days, factor, average } = qs.parse(
    location.search,
    { ignoreQueryPrefix: true }
  );
  const { data, error, loading } = useExport();

  useEffect(() => {
    if (isNil(error) && !data.length) {
      const daysToFetch: number =
        new Date().getDate() > parseInt(days.toString(), 10)
          ? new Date().getDate()
          : parseInt(days.toString(), 10);

      dispatch(
        fetchExport(
          workspace.toString(),
          token.toString(),
          daysToFetch,
          parseInt(factor.toString(), 10)
        )
      );
    }
  });

  if (error && !loading) {
    return (
      <Redirect
        to={{
          pathname: pathNames.settings,
          search: `?workspace=${workspace}&token=${token}&days=${days}&factor=${factor}&average=${
            average ? average : 7.6
          }`,
        }}
      />
    );
  }

  return (
    <div className={"appearance-none"}>
      <Helmet>
        <title>{"Toggl Export - Export"}</title>
      </Helmet>
      <div className={"bg-alert text-black p-4 text-center"}>API has been moved to a new hosting and might need some patience to load. Please contact Thomas or Annelies in case of persisting issues or with feedback. xoxo.</div>
      <div>
        <Balance
          exportData={data}
          average={average ? parseFloat(average as string) : 7.6}
        />

        <div className={"w-10/12 mx-auto max-w-3xl p-4"}>
        
          {!loading &&
            data &&
            MapHelper.mapResponse(data)
              .slice(0, parseInt(days.toString(), 10))
              .map((m, index) => <DaySummary key={index} data={m} />)}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Export;
