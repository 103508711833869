import React from 'react';
import { format } from 'date-fns';

import { Icon } from '..';

interface IProps {
	date: string;
	total: string;
	isOpen: boolean;
	handleToggle: (e: React.MouseEvent) => void;
}

const DayInfo: React.FC<IProps> = ({ date, total, isOpen, handleToggle }) => {
	return (
		<div className={"w-full flex shadow rounded-tl rounded-tr bg-main text-white font-bold py-3 px-6 justify-between cursor-pointer"} onClick={handleToggle}>
			<span>{format(new Date(date), 'EEEE')}, {format(new Date(date), 'dd')} {format(new Date(date), 'MMMM')} {format(new Date(date), 'yyyy')} | total: {total}</span>
			<Icon name={isOpen? "arrow_drop_down" : "arrow_drop_up"} color={"white"} />
		</div>
	);
}

export default DayInfo;