import './App.css';
import { Button, Label, Input, FormItem, Title, Paragraph, Credits, List, ProjectSummary, DayInfo, DaySummary, Icon } from './components';
import mock from './mock';
import { MapHelper } from './utils/map';

function App() {
  return (
    <div className="App">
      <Icon name={"face"} color={"main"} />
      <Icon name={"face"} color={"white"} />
      <Icon name={"face"} color={"black"} />
      {MapHelper.mapResponse(mock).map((m) => <DaySummary data={m} />)}
      <DayInfo date={"Friday, 03 September 2021"} total={"7.8"} handleToggle={() => {}} isOpen={true} />
      <ProjectSummary time={"4.7"} project={"SHD - LMR (Bebat) Fostplus RCL Q3 Contact FP"} description={"4u: RP-484 Contactformulier uitbreiden. 0.7u: RP-483 Verwijderen contactformulier onder elk FAQ item. "} />
      <List items={["test", "lol"]} />
      <Credits />
      <Paragraph text={[
        { type: 'text', content: 'Copy your workspace id. You can find it when you login and go to your ' },
        { type: 'link', content: 'reports', link: 'https://www.google.com' },
        { type: 'text', content: ". It's the last digit in the url." }
      ]} />
      <Title text={"Title"} />
      <Label label={"test"} />
      <Input handleInput={(e: React.FormEvent<HTMLInputElement>) => { console.log(e.currentTarget.value) }} />
      <Input type={"number"} handleInput={(e: React.FormEvent<HTMLInputElement>) => { console.log(e.currentTarget.value) }} />
      <Button label={"press"} handleClick={(e: React.MouseEvent) => { console.log("press") }} />
      <FormItem label={"Workspace ID"} handleInput={(e: React.FormEvent<HTMLInputElement>) => { console.log(e.currentTarget.value) }} />
      <FormItem label={"Workspace ID"} handleInput={(e: React.FormEvent<HTMLInputElement>) => { console.log(e.currentTarget.value) }} />
      <FormItem label={"Workspace ID"} handleInput={(e: React.FormEvent<HTMLInputElement>) => { console.log(e.currentTarget.value) }} />
    </div>
  );
}

export default App;
